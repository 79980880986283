<template lang="pug">
  .outgoing
    v-container
      PageTitle
      PeopleTable
</template>

<script>
import PeopleTable from "../components/PeopleTable.vue";
import PageTitle from "../components/PageTitle";

export default {
  components: {
    PageTitle,
    PeopleTable,
  },
};
</script>
