var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Alert',{attrs:{"type":"peopleStatements"}}),(_vm.filters.statement.value)?_c('div',{staticClass:"filter-info"},[_vm._v("Showing only people assigned with statement"),_c('strong',[_vm._v(" "+_vm._s(_vm.filterStatement)+". ")]),_c('router-link',{attrs:{"to":"/outgoing"}},[_vm._v("Reset")])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.columns,"items":_vm.people,"disable-pagination":true,"disable-sort":true,"item-key":"id","show-expand":"","single-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.total_paid === item.total),expression:"item.total_paid === item.total"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"green"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd")]),_vm._v("Paid")],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.total_paid > 0 &&item.total_paid !== item.total),expression:"item.total_paid > 0 &&item.total_paid !== item.total"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"orange"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd")]),_vm._v("Partial")],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.total_paid === 0 && item.total > 0),expression:"item.total_paid === 0 && item.total > 0"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"red"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd-off")]),_vm._v("Unpaid")],1)]}},{key:"item.unpaid",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!item.newest_name),expression:"!item.newest_name"}],staticClass:"not-available"},[_vm._v("All paid")]),(item.newest_name)?_c('div',{staticClass:"newest-unpaid"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.newest_name))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.parseDate(item.newest_date)))])]):_vm._e()]}},{key:"item.songwriting",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.songwriting_paid === item.songwriting, partial: item.songwriting_paid > 0}},[_vm._v(_vm._s(item.formatted.songwriting_paid))]),_c('div',{staticClass:"total"},[_vm._v("/ "+_vm._s(item.formatted.songwriting))])]}},{key:"item.mastering",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.mastering_paid === item.mastering, partial: item.mastering_paid > 0}},[_vm._v(_vm._s(item.formatted.mastering_paid))]),_c('div',{staticClass:"total"},[_vm._v("/ "+_vm._s(item.formatted.mastering))])]}},{key:"item.publishing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.publishing_paid === item.publishing, partial: item.publishing_paid > 0}},[_vm._v(_vm._s(item.formatted.publishing_paid))]),_c('div',{staticClass:"total"},[_vm._v("/ "+_vm._s(item.formatted.publishing))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid all",class:{full: item.total_paid === item.total, partial: item.total_paid > 0}},[_vm._v(_vm._s(item.formatted.total_paid))]),_c('div',{staticClass:"total all"},[_vm._v("/ "+_vm._s(item.formatted.total))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"details",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.columnsDropdown,"items":_vm.statements,"disable-pagination":true,"disable-sort":true,"item-key":"id"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.parseDate(item.date)))]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.paid),expression:"item.paid"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"green"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd")]),_vm._v("Paid")],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(!item.paid),expression:"!item.paid"}],staticClass:"status",attrs:{"outlined":"","pill":"","color":"red"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-currency-usd-off")]),_vm._v("Unpaid")],1)]}},{key:"item.songwriting",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.paid}},[_vm._v(_vm._s(item.formatted.songwriting))])]}},{key:"item.mastering",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.paid}},[_vm._v(_vm._s(item.formatted.mastering))])]}},{key:"item.publishing",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid",class:{full: item.paid}},[_vm._v(_vm._s(item.formatted.publishing))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"paid all",class:{full: item.paid}},[_vm._v(_vm._s(item.formatted.total))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"actions-list"},[_c('v-subheader',[_vm._v("Actions for \""+_vm._s(item.name)+"\"")]),_c('v-list-item',{on:{"click":function($event){return _vm.previewSummary(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file")])],1),_c('v-list-item-title',[_vm._v("Statement summary")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadSummary(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v("Download summary")])],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!item.paid),expression:"!item.paid"}],on:{"click":function($event){return _vm.setPaid(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-title',[_vm._v("Set as paid")])],1),(item.paid)?_c('v-list-item',{on:{"click":function($event){return _vm.setUnpaid(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-usd-off")])],1),_c('v-list-item-title',[_vm._v("Set as unpaid")])],1):_vm._e()],1)],1)]}}],null,true)})],1)]}},{key:"header.unpaid",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'date')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.date.value.length > 0}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-range")])]}}],null,true),model:{value:(_vm.filters.date.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.date, "dropdown", $$v)},expression:"filters.date.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Unpaid Date")]),_c('v-list-item',[_c('Datepicker',{model:{value:(_vm.filters.date.input),callback:function ($$v) {_vm.$set(_vm.filters.date, "input", $$v)},expression:"filters.date.input"}})],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('date')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.date.value.length > 0),expression:"filters.date.value.length > 0"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('date')}}},[_vm._v("Reset")])],1)])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(_vm._s(header.text)),_c('v-menu',{attrs:{"close-on-content-click":false},on:{"input":function($event){return _vm.hide($event, 'name')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:{active: _vm.filters.name.value}},'v-icon',attrs,false),on),[_vm._v("mdi-magnify")])]}}],null,true),model:{value:(_vm.filters.name.dropdown),callback:function ($$v) {_vm.$set(_vm.filters.name, "dropdown", $$v)},expression:"filters.name.dropdown"}},[_c('div',{staticClass:"v-menu__content--filter"},[_c('v-subheader',[_vm._v("Statement name")]),_c('v-text-field',{attrs:{"label":"Filter by name","dense":""},model:{value:(_vm.filters.name.input),callback:function ($$v) {_vm.$set(_vm.filters.name, "input", $$v)},expression:"filters.name.input"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.filter('name')}}},[_vm._v("Filter")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.filters.name.value),expression:"filters.name.value"}],attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.reset('name')}}},[_vm._v("Reset")])],1)])]}}])}),_c('v-pagination',{attrs:{"circle":"","length":_vm.peopleData.total_pages,"total-visible":"10"},model:{value:(_vm.filters.page.value),callback:function ($$v) {_vm.$set(_vm.filters.page, "value", $$v)},expression:"filters.page.value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }