<template lang="pug">
  div
    Alert(type="peopleStatements")
    .filter-info(v-if="filters.statement.value") Showing only people assigned with statement
      strong &nbsp; {{filterStatement}}. &nbsp;
      router-link(to='/outgoing') Reset
    v-data-table.elevation-2(:headers="columns" :items="people" :disable-pagination="true" :disable-sort="true" item-key="id" show-expand single-expand :expanded.sync="expanded")
      template(#item.paid="{ item }")
        v-chip.status(outlined pill color="green" v-show="item.total_paid === item.total")
          v-icon(small left) mdi-currency-usd
          | Paid
        v-chip.status(outlined pill color="orange" v-show="item.total_paid > 0 &&item.total_paid !== item.total")
          v-icon(small left) mdi-currency-usd
          | Partial
        v-chip.status(outlined pill color="red" v-show="item.total_paid === 0 && item.total > 0")
          v-icon(small left) mdi-currency-usd-off
          | Unpaid
      template(#item.unpaid="{ item }")
        .not-available(v-show="!item.newest_name") All paid
        .newest-unpaid(v-if="item.newest_name")
          .name {{item.newest_name}}
          .date {{ parseDate(item.newest_date) }}
      template(#item.songwriting="{ item }")
        .paid(:class="{full: item.songwriting_paid === item.songwriting, partial: item.songwriting_paid > 0}")
          | {{ item.formatted.songwriting_paid }}
        .total / {{ item.formatted.songwriting }}
      template(#item.mastering="{ item }")
        .paid(:class="{full: item.mastering_paid === item.mastering, partial: item.mastering_paid > 0}")
          | {{ item.formatted.mastering_paid }}
        .total / {{ item.formatted.mastering }}
      template(#item.publishing="{ item }")
        .paid(:class="{full: item.publishing_paid === item.publishing, partial: item.publishing_paid > 0}")
          | {{ item.formatted.publishing_paid }}
        .total / {{ item.formatted.publishing }}
      template(#item.total="{ item }")
        .paid.all(:class="{full: item.total_paid === item.total, partial: item.total_paid > 0}")
          | {{ item.formatted.total_paid }}
        .total.all / {{ item.formatted.total }}



      template(#expanded-item="{ headers, item }")
        td.details(:colspan="headers.length")
          v-data-table.elevation-0(:headers="columnsDropdown" :items="statements" :disable-pagination="true" :disable-sort="true" item-key="id")
            template(#item.date="{ item }")
              | {{ parseDate(item.date) }}
            template(#item.paid="{ item }")
              v-chip.status(outlined pill color="green" v-show="item.paid")
                v-icon(small left) mdi-currency-usd
                | Paid
              v-chip.status(outlined pill color="red" v-show="!item.paid")
                v-icon(small left) mdi-currency-usd-off
                | Unpaid

            template(#item.songwriting="{ item }")
              .paid(:class="{full: item.paid}") {{ item.formatted.songwriting }}
            template(#item.mastering="{ item }")
              .paid(:class="{full: item.paid}") {{ item.formatted.mastering }}
            template(#item.publishing="{ item }")
              .paid(:class="{full: item.paid}") {{ item.formatted.publishing }}
            template(#item.total="{ item }")
              .paid.all(:class="{full: item.paid}") {{ item.formatted.total }}

            template(#item.actions="{ item }")
              v-menu(bottom left)
                template(#activator="{ on, attrs }")
                  v-btn(icon v-on="on" v-bind="attrs" )
                    v-icon(color="primary") mdi-dots-vertical
                v-list.actions-list
                  v-subheader Actions for "{{item.name}}"
                  v-list-item(@click="previewSummary(item)")
                    v-list-item-icon
                      v-icon mdi-file
                    v-list-item-title Statement summary
                  v-list-item(@click="downloadSummary(item)")
                    v-list-item-icon
                      v-icon mdi-download
                    v-list-item-title Download summary

                  v-list-item(v-show="!item.paid" @click="setPaid(item)")
                    v-list-item-icon
                      v-icon mdi-currency-usd
                    v-list-item-title Set as paid
                  v-list-item(v-if="item.paid" @click="setUnpaid(item)")
                    v-list-item-icon
                      v-icon mdi-currency-usd-off
                    v-list-item-title Set as unpaid

      template(v-slot:header.unpaid="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'date')" v-model="filters.date.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.date.value.length > 0}") mdi-calendar-range
          .v-menu__content--filter
            v-list(dense)
              v-subheader Unpaid Date
              v-list-item
                Datepicker(v-model="filters.date.input")
            v-btn(color="primary" @click="filter('date')") Filter
            v-btn(color="primary" small text @click="reset('date')" v-show="filters.date.value.length > 0") Reset
      template(v-slot:header.name="{ header }")
        | {{ header.text }}
        v-menu(:close-on-content-click="false" @input="hide($event, 'name')" v-model="filters.name.dropdown")
          template(v-slot:activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on" :class="{active: filters.name.value}") mdi-magnify
          .v-menu__content--filter
            v-subheader Statement name
            v-text-field(label="Filter by name" dense v-model="filters.name.input")
            v-btn(color="primary" @click="filter('name')") Filter
            v-btn(color="primary" small text @click="reset('name')" v-show="filters.name.value") Reset
    v-pagination(v-model="filters.page.value" circle  :length="peopleData.total_pages" total-visible="10")
</template>

<script>
import { mapState } from "vuex";
import Alert from "@/components/Alert";

import api from '@/api'
import Datepicker from "./Datepicker";


export default {
  data() {
    return {
      filters: {
        date: {
          dropdown: false,
          value: [],
          input: null
        },
        statement: {
          value: null,
        },
        name: {
          dropdown: false,
          value: null,
          input: null
        },
        page: {
          value: 1,
        },
      },
      shown: null,
      dates: [
        {
          name: 'Today',
          value: '1 day'
        },
        {
          name: 'This week',
          value: '1 week'
        },
        {
          name: 'This month',
          value: '1 month'
        },
        {
          name: 'This year',
          value: '1 year'
        }
      ],
      expanded: [],
      statements: [],
      columns: [],
      columnsDropdown: [],
      filterStatement: null,
    };
  },
  components: {
    Datepicker,
    Alert
  },
  computed: {
    ...mapState({
      peopleData: state => state.outgoing.people,
      people: state => state.outgoing.people.items,
    }),
  },



  methods:{
    async setPaid(row, noAlert){
      await this.$store.dispatch('outgoing/updatePaidStatus', {
        personId: this.shown,
        id: row.id,
        paid: true
      });
      await this.getPersonStatements();
      if(!noAlert){
        this.$store.commit('popups/addPeopleStatementsAlert', {
          type: 'toggle-paid',
          action: 'paid',
          name: row.name,
          client: row.client,
          person: this.expanded[0].name,
          callback: () => this.setUnpaid(row, true)
        })
      }else{
        this.soundEffectWithoutAlert('paid');
      }
    },
    async setUnpaid(row, noAlert){
      await this.$store.dispatch('outgoing/updatePaidStatus', {
        personId: this.shown,
        id: row.id,
        paid: false
      });
      await this.getPersonStatements();
      if(!noAlert){
        this.$store.commit('popups/addPeopleStatementsAlert', {
          type: 'toggle-paid',
          action: 'unpaid',
          client: row.client,
          name: row.name,
          person: this.expanded[0].name,
          callback: () => this.setPaid(row, true)
        })
      }else{
        this.soundEffectWithoutAlert('unpaid');
      }
    },
    soundEffectWithoutAlert(sound){
      this.$store.commit('popups/playSoundEffect', sound);
    },
    parseDate(dateString){
      const date = new Date(dateString);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    },

    filter(name){
      this.filters[name].value = this.filters[name].input;
      this.filters[name].dropdown = false;
      this.filters.page.value = 1;

      this.getPeople();
    },
    reset(name){
      if(typeof this.filters[name].value === 'object'){
        this.filters[name].input = [];
        this.filters[name].value = [];
      }else{
        this.filters[name].input = null;
        this.filters[name].value = null;
      }
      this.filters[name].dropdown = false;
      this.filters.page.value = 1;

      this.getPeople();
    },
    hide(shown, name){
      if(!shown){
        this.filters[name].input = this.filters[name].value;
      }
    },
    downloadSummary(item){
      window.location = `${api.api}/export/pdf/person-statement/${this.shown}/${item.id}/download`;
    },
    previewSummary(item){
      window.open(`${api.api}/export/pdf/person-statement/${this.shown}/${item.id}`, '_blank');
    },
    setupTable(){
      this.columns = [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Newest unpaid',
          value: 'unpaid',
        },
        {
          text: 'Songwriting',
          value: 'songwriting',
        },
        {
          text: 'Publishing',
          value: 'publishing',
        },
        {
          text: 'Master',
          value: 'mastering',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: 'Status',
          value: 'paid',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ];
      this.columnsDropdown = [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Source',
          value: 'client',
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Songwriting',
          value: 'songwriting',
        },
        {
          text: 'Publishing',
          value: 'publishing',
        },
        {
          text: 'Master',
          value: 'mastering',
        },
        {
          text: 'Total',
          value: 'total',
        },
        {
          text: 'Status',
          value: 'paid',
        },
        {
          text: '',
          value: 'actions',
        },
      ]
    },
    async getFilterStatementName(){
      if(this.filters.statement.value){
        const filterStatementItem = await this.$store.dispatch('incoming/getStatement', this.filters.statement.value);
        if(filterStatementItem){
          this.filterStatement = filterStatementItem.name;
        }else{
          this.filterStatement = 'N/A';
        }
      }else{
        this.filterStatement = 'N/A';
      }
    },
    async getPeople(){
      return await this.$store.dispatch("outgoing/getPeople", this.filters);
    },
    async getPersonStatements(){
      this.statements = await this.$store.dispatch('outgoing/getPersonStatements', this.shown);
    }
  },
  async mounted() {
    await this.getPeople();
    this.setupTable();
  },
  watch: {
    async expanded(value) {
      const item = value[0]
      if(item && item.id){
        this.shown = item.id;
      }
      this.statements = [];
      this.getPersonStatements();
    },
    'filters.page.value'() {
      this.getPeople();
    },
    $route: {
      handler: function(){
        this.filters.statement.value = this.$route.params.id;
        this.getFilterStatementName();
        this.getPeople();
      },
      immediate: true
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../styles/variables";





::v-deep {
  .payment-action{
    cursor: pointer;

    &.unpaid{
      color: #0395E8;
    }
    &.paid{
      color: #7A7A7A;
    }

    .add-button{
      border-radius: 100%;
      font-size: 35px;
    }
  }

  .v-icon.selected-icon{
    position: absolute;
    right: 10px;
  }

  .paid{
    font-size: 21px;


    font-weight: 100;
    color: $error;

    padding-top: 5px;

    &.partial{
      color: $warning;
    }

    &.full{
      color: $success;
    }

    &.all{
      font-weight: 600;
    }
  }
  .total{
    font-size: 17px;
    font-weight: 200;

    padding-bottom: 5px;
  }

  .v-chip.status{
  }

  .newest-unpaid{
    .name{
      font-weight: bold;
    }
  }

  .not-available{
    font-size: 17px;
    color: $success;
  }








  .v-data-table__expanded{

    &.v-data-table__expanded__row{
      background-color: rgba($primary, 0.075);
    }


    td.details{
      padding: 0px;

      .v-data-table{
        border-radius: 0;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.25) !important;
      }
    }
  }

  .v-data-table{
    .v-data-table-header{
      .v-icon{
        margin-left: 10px;
      }
    }

    .v-data-footer{
      display: none;
    }
  }
  .actions-list{
    .v-list-item{
      cursor: pointer;
      text-align: left;

      .v-list-item__icon{
        margin-right: 15px;
      }
      &:hover{
        background-color: $secondary;
      }

    }
  }


}

</style>
